import React from "react";
import { graphql, Link } from "gatsby";
import get from "lodash/get";
import Helmet from "react-helmet";
import Layout from "../components/layout";
import ArticlePreview from "../components/article-preview";
import bannerImg from "../img/kiwokbanner2024.png";
import awards from "../img/Partners.png";
import video from "../img/video.png";
import client from "../img/client.png";
import team from "../img/team.png";
import storyPhoto from "../img/bjorlin_photo.png";
import Popup from '../components/popup'

//Icon set
import distanceDiagnosis from "../img/ICONS_DistanceDiagnos.png";
import homeMonitoring from "../img/ICONS_Home.png";
import screening from "../img/ICONS_Screening.png";
import iconCostSaving from "../img/ICONS_CostSaving.png";
import iconLongTerm from "../img/ICONS_LongTerm.png";
import iconSecure from "../img/ICONS_Secure.png";
import iconDistance from "../img/ICONS_Distance.png";
import iconCTA from "../img/ICONS_CTA.png";

//Team images
import profileAndersBjorlin from "../img/Profile_AndersBjorlin.jpg";
import profileAndersOstlund from "../img/Profile_AndersOstlund.jpg";
import profileAnnika from "../img/Profile_Annika.jpg";
import profileKlasNystrom from "../img/Profile_KlasNystrom.jpg";
import profileBjornErik from "../img/Profile_BjornErik.jpg";
import { left } from "inquirer/lib/utils/readline";

class RootIndex extends React.Component {
/*   togglePopup(){
    document.getElementById("popup-1").classList.toggle("active");
} */
  
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title");
    const posts = get(this, "props.data.allContentfulBlogPost.edges");
    //const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout location={this.props.location}>
        {/* <Popup /> */}
        <div style={{ background: "#fff" }}>
          <Helmet title={siteTitle} />
          <img className="bannerImg" src={bannerImg}></img>
          <div className="intro">
            <div className="intro-text">
              <h1 className="introHeader">
              Sustainable & Preventive Healthcare 
              Through Remote Monitoring
              </h1>
{/*               <p style={{fontWeight: "bold", color: "#919191", fontSize: "13px"}}>
              Disclaimer:
              Kiwok develops digital health solutions for Remote Patient Monitoring. The present products are not CE-marked and for the moment they are not offered for clinical use on patients. The products were earlier certified in accordance 
              with the European regulation Medical Device Directive (MDD) and Kiwok has initiated a process to be compliant with the new European regulation Medical Device Regulations (MDR). Therefore, the products described on this website are currently not offered for clinical use on patients. During the certification process we offer solutions and products for research purpose only and we 
              seek R&D collaboration with academia, research clinics, medtech colleagues and pharma companies.
              </p> */}
              {/* <p>
                Prior to the Corona pandemic, healthcare did not have sufficient
                resources and preventive awareness to cope with the increasing
                needs resulting from further patient longevity and welfare
                illnesses… and now we can add the urgent need to be able to
                separate patients with different medical disorders in order to
                avoid the spread of virus infections. Remote patient monitoring
                can be a driver for change. Elderly patients with comorbid
                conditions shall not meet patients with virus infection in
                hospitals or primary care centres. Healthcare on a distance can
                solve that and at the same time we will get a more efficient and
                preventive healthcare.
              </p> */}
              <p>We are looking for the best medical researchers in the world 
                to collaborate on the most important development project 2024 
                and moving forward. Help us in our journey to make healthcare 
                obsolete and add 10 more healthy years to everyone's life.
              </p>
              <Link className="btn-intro" to="/bodykom#contact">
              BOOK A MEETING
              </Link>
            </div>
          </div>
          <div className="introVideo">
            <iframe className="videoFrame" width="720" height="405" src="https://www.youtube.com/embed/MGRopJkXee0"></iframe>
          </div>
            <div className="mainContentWrapper">
              <div className="about">
                <h3>PARTNERS</h3>
                <img className="awards-img" src={awards} alt="Awards"></img>
                <div className="greyBackground">
                <div className="innerWrapper">
                <h3>Remote Patient Monitoring</h3>
                <p>
                  Kiwok develops and offers solutions for remote patient
                  monitoring (RPM). Initially we developed continuous long-term
                  multi-channel ECG and we continue to be able to supplement
                  solutions for other therapy areas through collaboration with
                  other vendors.
                </p>
                </div>
                <h2 className="applicationHeader">Application Areas</h2>
                <div className="wrapper1">
                  <div className="wrapper-child">
                    <img
                      className="about-img"
                      src={distanceDiagnosis}
                      alt="Bodykom"
                    ></img>
                    <p className="boldedStart">Distance diagnosis</p>
                    <p>of individual patients on a distance at clinical level</p>
                  </div>
                  <div className="wrapper-child">
                    <img
                      className="about-img"
                      src={homeMonitoring}
                      alt="Bodykom"
                    ></img>
                    <p className="boldedStart">Home monitoring</p>
                    <p>
                      of patients with chronical/multiple disorders at regional
                      level
                    </p>
                  </div>
                  <div className="wrapper-child">
                    <img
                      className="about-img"
                      src={screening}
                      alt="Bodykom"
                    ></img>
                    <p className="boldedStart">Screening</p>
                    <p>
                    of individuals in the risk zone for illness on a national level
                    </p>
                  </div>
                </div>
                <h2>Additional areas</h2>
                <p>
                  Kiwok offers analysis service for customers without own analysis
                  capacity. Analysis is made by Biomedical analysts and delivered
                  via internet.
                </p>
                </div>
                <h3>BodyKom in action</h3>
                <iframe className="videoFrame" width="720" height="405" src="https://www.youtube.com/embed/oNn0Yu23rsk"></iframe>
              </div>

              <div className="clients-page">
              <div className="wrapper1">
                  <div className="wrapper-child">
                    <img src={storyPhoto} alt="Client" style={{paddingTop: "50px"}}></img>
                    <p>Anders, founder of Kiwok</p>
                  </div>
                  <div className="wrapper-child">
                    <h3 className="client-name" style={{paddingTop: "50px"}}>ANDERS TELLS HIS STORY</h3>
                    <p className="client-jobtitle">
                    </p>
                    <p>
                    ”I was diagnosed with atrial fibrillation. and went through all kinds of treatments.
                    For a long while I would often faint, which was rather unpleasant.
                    I asked my doctor if it wouldn’t be better if he used a mobile device that let you know immediately if something happened.
                    he answered that no such tool exists…”
                    </p>
                  </div>
                </div>
                </div>

              <div className="about">
                <h2>
                  How we provide accessible, equal, engaging and sustainable care
                </h2>
                <div className="wrapper2">
                  <div className="wrapper-child4">
                    <img
                      className="about-img"
                      src={iconCostSaving}
                      alt="Bodykom"
                    ></img>
                    <p className="boldedStart">Efficient and cost- saving care process</p>
                    <p>Reduces costs, travelling, queue time</p>
                  </div>
                  <div className="wrapper-child4">
                    <img
                      className="about-img"
                      src={iconLongTerm}
                      alt="Bodykom"
                    ></img>
                    <p className="boldedStart">Connected continuous long-term measurement</p>
                    <p>Early detection and diagnoses which improves treatment</p>
                  </div>
                  <div className="wrapper-child4">
                    <img
                      className="about-img"
                      src={iconSecure}
                      alt="Bodykom"
                    ></img>
                    <p className="boldedStart">Secure data communication</p>
                    <p>
                      Reduces the risk for circulation of personal health data
                    </p>
                  </div>
                  <div className="wrapper-child4">
                    <img
                      className="about-img"
                      src={iconDistance}
                      alt="Bodykom"
                    ></img>
                    <p className="boldedStart">Distance Monitoring</p>
                    <p>
                      Provides accessible, equal, engaging and sustainable care
                    </p>
                  </div>
                </div>
                <div className="greyBackground">
                <h2>If you find our solutions interesting, please contact us for an unconditional call</h2>
                <div className="CTAImage">
                <img className="about-img"
                      src={iconCTA}
                      alt="Bodykom"
                    ></img>
                    </div>
                    <Link className="btn-outro" to="/bodykom#contact">
                    BOOK A MEETING
                </Link>
                </div>
              </div>

              <div className="the-team">
                <h3>Meet The Team</h3>
                <h2 className="teamHeader">Board of Directors</h2>
                <div className="wrapperTeam">
                  <div className="wrapper-child-Team">
                    <div className="profile-card">
                      <img src={profileAndersOstlund} alt="Team"></img>
                      <h3 className="profile-name">Anders Östlund</h3>
                      <p className="team-jobtitle">Chairman</p>
                      <input type="checkbox" className="read-more-state" id="post-1"/>
                      <p className="read-more-wrap" style={{textAlign: "left"}}>
                      Executive chairman of the Board (born 1950). Elected to the Board on May 28, 2015. Entrepreneur with 40 years of experience from Private Equity investments and financial advice. 
                      <span className="read-more-target"> Anders has primarily held board positions in small and medium-sized companies, in Sweden, Switzerland, Germany and Norway, and acted on the basis of an ownership position in these companies. Anders has a full-time commitment to Kiwok and he is together with a partner the largest shareholder in Kiwok.
                      </span></p>
                      <label for="post-1" className="read-more-trigger" style={{border: "none"}}></label>
                    </div>
                  </div>
                  <div className="wrapper-child-Team">
                    <div className="profile-card">
                      <img src={profileBjornErik} alt="Team"></img>
                      <h3 className="profile-name">Björn-Erik Erlandsson</h3>
                      <p className="team-jobtitle"><br></br></p>
                      <input type="checkbox" className="read-more-state" id="post-2" />
                      <p className="read-more-wrap" style={{textAlign: "left"}}>
                        Ph. D. Chalmers University of Technology, Gothenburg, Sweden, Applied Electronics. Professor, Senior Advisor at Chemistry, Biotechnology and Health, KTH Royal Institute of Technology, Stockholm, technology and quality in health care.
                        <span className="read-more-target"> Research and development, quality and regulatory issues from international industries, Siemens and Nobel Industries. Director of Medical Informatics and Technology, University Hospital, Umeå and Academic Hospital, Uppsala, Professor Medical Technology. Standardization work in medical technology and health informatics, chairman of SIS/TK334, Swedish HoD at standardization organizations; CEN and ISO, Chairman of the JWG on Software and Medical Equipment (SAMD) within CEN/CENELEC. Convenor ISO/TC215 Health Informatics Standardization, WG1 Architecture, Framework and Models. Chairman of the Board of HL7 Sweden.
                      </span></p>
                      <label for="post-2" className="read-more-trigger" style={{border: "none"}}></label>
                    </div>
                  </div>
                  <div className="wrapper-child-Team">
                    <div className="profile-card">
                      <img src={profileKlasNystrom} alt="Team"></img>
                      <h3 className="profile-name">Klas Nyström</h3>
                      <p className="team-jobtitle">CEO</p>
                      <input type="checkbox" className="read-more-state" id="post-4" />
                      <p className="read-more-wrap" style={{textAlign: "left"}}>
                        CEO and executive member of the Board (born 1955), former CEO of HiQ Karlskrona AB, 1.5 years in the company, 26 years in senior positions in the Telecom, IoT and ICT industry, 14 years as an officer in the Royal Navy.
                        <span className="read-more-target"> Klas is independent of the company and its management.
                      </span></p>
                      <label for="post-4" className="read-more-trigger" style={{border: "none"}}></label>
                    </div>
                  </div>
                </div>

                <h2 className="teamHeader">Management</h2>
                <div className="wrapperTeam">
                <div className="wrapper-child-Team">
                    <div className="profile-card">
                      <img src={profileKlasNystrom} alt="Team"></img>
                      <h3 className="profile-name">Klas Nyström</h3>
                      <p className="team-jobtitle">CEO</p>
                      <input type="checkbox" className="read-more-state" id="post-5" />
                      <p className="read-more-wrap" style={{textAlign: "left"}}>
                      CEO and executive member of the Board (born 1955), former CEO of HiQ Karlskrona AB, 1.5 years in the company, 26 years in senior positions in the Telecom, IoT and ICT industry, 14 years as an officer in the Royal Navy.
                        <span className="read-more-target"> Klas is independent of the company and its management.
                      </span></p>
                      <label for="post-5" className="read-more-trigger" style={{border: "none"}}></label>
                    </div>
                  </div>
                  <div className="wrapper-child-Team">
                    <div className="profile-card">
                      <img src={profileAnnika} alt="Team"></img>
                      <h3 className="profile-name">Annika Grünfeld</h3>
                      <p className="team-jobtitle">CFO</p>
                      <input type="checkbox" className="read-more-state" id="post-7" />
                      <p className="read-more-wrap" style={{textAlign: "left"}}>
                      Controller (born 1966). Authorized Business Advisor International Project Economist, Census, Växjö IIU, Institute for International Education at Stockholm University, International Business Administration 2 years, Munich, Brighton. 
                      <span className="read-more-target"> During 2015-2019, was operations manager for NyföretagarCentrum Ronneby.
                      Has since 2008 run an accounting operations in her own company, Off4All AB, Ronneby. Authorized Accounting Consultant via FAR. Annika works as financial manager in the group
                      </span></p>
                      <label for="post-7" className="read-more-trigger" style={{border: "none"}}></label>
                    </div>
                  </div>
                </div>
                <h2 className="teamHeader">Founder</h2>
                <div className="wrapperTeam">
                  <div className="wrapper-child-Team">
                    <div className="profile-card">
                    <img src={profileAndersBjorlin} alt="Team"></img>
                      <h3 className="profile-name">Anders Björlin</h3>
                      <p className="team-jobtitle">CTO</p>
                      <p style={{textAlign: "left"}}>
                      Founder of Kiwok (born 1943). Master of Science in Engineering and studied business administration at Stockholm University. Has been Logistics responsible within Nynäs Petroleum and Management consultant at WM-data AB. Anders was Kiwok's first patient and former CEO of the company.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              
            {/* <div className="container">
              <div className="wrapper">
                <h2 className="section-headline">Recent articles</h2>
                <ul className="article-list">
                  {posts.map(({ node }) => {
                    return (
                      <li key={node.slug}>
                        <ArticlePreview article={node} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </Layout>
    );
  }
  
}

export default RootIndex;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
